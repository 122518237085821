import React from "react";
import {
  AppBank,
  AppBottledGas,
  AppBroadbandHome,
  BroadBandLogo,
  BottleGasLogo,
  AppCalendar,
  AppElectricity,
  Gas,
  ServiceEnvelope,
  ServiceCircle,
  BroadBandCircle,
  AppHotMugCircle,
  AppBottleGasCircle,
  AppWalletCircle,
  AppUserUnknown,
} from "react-components";

import electricityImage from "../Assets/Images/electricity.svg";
import pricintgImage from "../Assets/Images/pricing.svg";
import smileImage from "../Assets/Images/smilie.svg";
import calentarImage from "../Assets/Images/calendar.svg";
import {
  BILLING_CYCLE_FORTNIGHTLY,
  BILLING_CYCLE_MONTHLY,
  BILLING_CYCLE_WEEKLY,
  PAYMENT_METHOD_BILL,
  PAYMENT_METHOD_PREPAY,
} from "./TypedConstants";

const consumerCarePdfUrl =
  process.env.NX_PDF_BASE_URL +
  "/-/media/contact/pdfs/support/consumer-care-policy?t=" +
  new Date().getTime();
const generalTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-general-terms-and-conditions.pdf";
const planTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-plan-terms-and-conditions.pdf";
const broadbandTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-broadband-terms-and-conditions.pdf";
const bottleGasTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/rockgas-lpg-tcs-for-cylinder-and-bulk-supply.pdf";
const pipedGasTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/reticulated-lpg-terms-and-conditions.pdf";
const planTermsAndConditionsURL =
  process.env.NX_EXPRESS_BASE_URL +
  planTermsAndConditions +
  "?t=" +
  new Date().getTime();
const ddTermsAndConditions =
  process.env.NX_EXPRESS_BASE_URL +
  "/-/media/contact/pdfs/terms-and-conditions/join/direct-debit-terms.pdf?t=" +
  new Date().getTime();
const periodTermsAndConditions =
  process.env.NX_EXPRESS_BASE_URL +
  "/-/media/contact/pdfs/terms-and-conditions/join/weekly-billing-special-terms.pdf?t=" +
  new Date().getTime();
const prepayTermsAndConditions =
  process.env.NX_EXPRESS_BASE_URL +
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-prepay-special-terms.pdf?t=" +
  new Date().getTime();
const bottleGasPricing =
  "https://firstgasnz.sharepoint.com/sites/CONT/SitePages/Home.aspx";
const bottleGasLocationNumber =
  "https://firstgasnz.sharepoint.com/sites/CONT/SitePages/Home.aspx";
export const NEW_BUILD_URL =
  "https://contact.co.nz/support/building-a-new-home";

export const SERVICE_ID_ELECTRICITY = "ELEC";
export const SERVICE_ID_PIPED_GAS = "GAS";
export const SERVICE_ID_BROADBAND = "BB";

export const SMART_METER = "SMART METER";

export const USAGE_TYPE_STANDARD = "S";
export const USAGE_TYPE_LOW = "L";
export const BASIC_PLAN = "BASIC";
export const BACH_PLAN = "BACH";

export const STANDARD_USER = "Standard";

export const SERVICE_ELEC = "Electricity";

export const SERVICE_GAS = "Piped Gas";

export const CUSTOMER_TYPE = "Residential";

export const JOIN_JOURNEY_TYPE = "Join";

export const MOVE_JOURNEY_TYPE = "Move";

export const ADD_JOURNEY_TYPE = "Add";

export const CHANGE_JOURNEY_TYPE = "Change";

export const SERVICES_NAMES = {
  [SERVICE_ID_ELECTRICITY]: "electricity",
  [SERVICE_ID_PIPED_GAS]: "piped gas",
  [SERVICE_ID_BROADBAND]: "broadband",
};

export const SERVICES_SHORT_NAMES = {
  elec: "ELEC",
};

export const BB_BUNDLE_PLAN_ID = "BB_BUNDLE";

export const PLAN_START_DATE_FORMAT = "dd/MMM/yyyy";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_PICKER_DATE_FORMAT = "dd/MM/yyyy";

export const SESSION_EXPIRED_ERROR_MESSAGE = `Apologies, your current session has expired. Please click ‘close’ to reload the page or contact us <a href="https://contact.co.nz/support/contact-us" target="_blank" rel="noopener noreferrer">here</a>.`;

export const PHONE_MOBILE = "mobile";
export const PHONE_HOME = "home";

export const VULNERABILITY_OTHER = "Vulnerability other";

export const NO_ICP_WARNING_MESSAGE =
  "%serviceICP% ICP missing, please provide ICP# before continuing.";

export const Header = {
  logo: {
    redirectURL: process.env.NX_EXPRESS_BASE_URL + "/residential/",
    alt: "Contact Energy Logo",
  },
  support: {
    label: "Support",
    url: process.env.NX_EXPRESS_BASE_URL + "/support",
  },
};

export const INACTIVE_CONNECTION_STATUSES = ["INACT", "inactive"];

export const SELECT_ZONE = "Select zone";

const BRANCH = "Branch";

const FRANCHISE = "Franchise";

export const yesNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const AboutYou = {
  cardTitle: "About you",
  creditCheckTitle: "Credit check",
  salutationTitle: "Title/Salutation",
  selectTitle: "Select one",
  unknownTitle: null,
  labelTitle: "Title",
  errorTitle: "Title required",
  salutationDescription:
    "Please provide your full legal name as per your NZ drivers licence or passport.",
  salutationLabels: ["Mr", "Mrs", "Miss", "Ms", "Mx"],
  preferredNameLabel: "Preferred name",
  preferredNameMaxLength: 30,
  preferredNamePlaceholder: "",
  firstNameLabel: "First name",
  firstNamePlaceholder: "",
  firstNameMaxLength: 30,
  firstNameRequiredMsg: "First name is required",
  nameNotMatchMsg: "Please check name matches your ID",
  dobNotMatchMsg: "Please check DOB matches your ID",
  middleNameLabel: "Middle name",
  middleNamePlaceholder: "",
  middleNameMaxLength: 30,
  lastNameLabel: "Last name",
  lastNamePlaceholder: "",
  lastNameMaxLength: 30,
  lastNameRequiredMsg: "Last name is required",
  dateLabel: "Date of birth",
  datePlaceholder: "DD / MM / YYYY",
  dateFormat: DATE_FORMAT,
  dateMinAge: 18,
  dateMinAgeErrorMsg: "You must be over 18 to signup",
  dateMaxAge: 110,
  dateMaxAgeErrorMsg: "Please check your date of birth",
  numberYearOptions: 82,
  dateRequiredMsg: "Date of birth is required",
  invalidDateMsg: "Invalid date",
  phoneLabel: "Phone no.",
  phonePlaceholder: "e.g. 091234567",
  phoneMaxLength: 11,
  phoneMaxLengthUnrestricted: 14,
  phoneRequiredMsg: "Phone number is required",
  phoneInvalidMsg: "Invalid phone number",
  emailLabel: "Email",
  emailPlaceholder: "",
  emailMaxLength: 50,
  emailRequiredMsg: "Email is required",
  emailInvalidMsg: "Please enter a valid email address",
  emailNotMatchMsg: "Email address doesn't match",
  emailSubtext: `<p>Your bills and other communications will be sent here so please double check you have typed in your email address correctly.</p>
                <p>
                  We may contact you to ensure you complete your signup successfully.
                </p>`,
  creditCheckLabel:
    "I give permission to use the personal information for the purposes of conducting a credit check",
  creditCheckSubLabel:
    "Giving us your driver licence number will help speed up the credit check process.",
  driverLabel: "Driver Licence no.",
  driverLicenseLabel: "NZ Driver Licence no.",
  driverLicenseRequiredMsg: "NZ Driver Licence no. is required",
  driverPlaceholder: "AA000000",
  driverMaxLength: 8,
  driverRequiredMsg: "Driver Licence no. is required",
  driverInvalidMsg: "Licence number format is incorrect",
  cardNumberLabel: "Card version no.",
  cardNumberPlaceholder: "000",
  cardNumberMaxLength: 3,
  cardNumberRequiredMsg: "Card version no. is required",
  cardNumberInvalidMsg: "Card version number format is incorrect",
  buttonLabel: "Continue",
  previewDate: "Date of birth:",
  previewPhone: "Phone number:",
  previewEmail: "Email:",
  previewDriver: "Driver licence number:",
  previewCardNumber: "Card version number:",
  newsCheckboxLabel:
    "I’m happy to get emails and text messages about new products and plans every now and then so I don’t miss out on the latest deals. News, energy tips and other offer emails and text messages are fine too but please don’t send me too many.",
  newsAndOffersTitle:
    "Send me occasional special offers, new plan recommendations and other news by email and SMS",
  newsAndOffersMessage: "You can always opt out later if you want to.",
  newsAndOffersAcceptLabel: "Yes",
  newsAndOffersDeclineLabel: "No",
  checkIdentity: true,

  makeChanges: "Make changes.",
  pressUpdate: "Press update to commit.",
  driverLicenceTitle: "NZ Driver’s Licence",
  checkText: "Check & amend driver license and personal details to proceed.",
  modulePersonRemove: {
    removeModuleHeader: "WARNING",
    removeModuleText:
      "Are you sure you want to remove this person from the account? this change is permanent.",
    removeModuleConfirmButton: "DELETE",
    removeModuleCancelButton: "CANCEL",
  },
  identityCheckErrorTitle: "Could not verify driver licence details.",
  identityCheckErrorDetails:
    "Driver licence details or version number may not be correct. Please double check your details and try again.",
  lpgLabel: "Include for LPG",
};

export const SITUATION_NOT_MOVING = "Changing energy supplier";
export const SITUATION_MOVING = "Moving house";
export const SITUATION_CHANGE_OWNERSHIP = "Changing of ownership";

export const MedicalDependency = {
  cardTitle: "Medical dependency",
  previewHasVulnerablePerson: "Someone is vulnerable at this property",
  previewHasMedicalDependant:
    "Someone at this property is medically dependent on electricity or piped gas",
  previewMedicalDependantDifferentAsAccountHolder:
    "The medically dependent person isn't the account holder",
  previewMedicalDependantSameAsAccountHolder:
    "The medically dependent person is the account holder",
  previewCriticalMedicalEquipment:
    "Details of the critical medical equipment: ",
  previewMedicalDependantName: "Medically dependent person: ",
  previewMedicalDependantPhone: "Medically dependent person phone: ",
  previewMedicalDependantEmail: "Medically dependent person email: ",
  previewContactHealthPractitioner:
    "I authorise Contact Energy to contact my health practitioner to collect information about you for this purpose",
  previewPracticeName: "Practice name: ",
  previewPractitionerName: "Practitioner: ",
  previewPracticePhone: "Practice phone number: ",
  vulnerableQuestionModalContent: {
    title:
      "Contact takes its obligation to assist customers in vulnerable circumstances seriously.",
    consider: "You can be considered vulnerable if you either: ",
    bulletPoints: [
      "rely on electricity due to age, health or disability and a power cut may threaten your health or well-being.",
      "have genuine difficulty paying bills due to financial hardship.",
    ],
    content1:
      "If you think you or your family fits this description or you are finding it difficult to pay your energy bills, please contact us immediately on 0800 80 9000. " +
      "We’ll discuss options such as setting up a payment arrangement, applying to Work and Income or another social agency for budgeting or other advice or assistance.",
    content2:
      "Also, if you believe that at some time in the future you may have difficulties with your payments or with communicating with us, you can appoint one or more alternate contacts who agree to assist if a disconnection or payment issue is pending. " +
      "An alternate contact could be a family member or friend, or a social agency support person.",
    buttonLabel: "Close",
  },
  medicalQuestionModalContent: {
    title1:
      "Do you have medical equipment at home that depends on electricity or piped gas?",
    para1:
      "If you or someone in your household depends on mains electricity or piped gas for critical medical support, " +
      "and the loss of either electricity or piped gas could result in loss of life or serious harm, then let us know when you sign up. " +
      "Medical dependence relates to both medical equipment or other electrical equipment needed " +
      "to support a treatment regime such as a microwave to heat fluids for renal dialysis.",
    title2:
      "Your emergency back-up plan during an electricity or piped gas outage",
    para2:
      "If you're medically dependent, it's important that you have an emergency back-up plan during an electricity or piped gas outage. " +
      "This can occur suddenly during bad weather or unplanned emergency repairs.",
    bulletPointsTitle: "Your back-up plan could include:",
    bulletPoints: [
      "Having a back-up battery that is always charged up or portable/camping gas stove",
      "Relocating to a friend or family member's house",
      "If it's really serious, then calling an ambulance if your situation is serious. to be taken to the hospital",
    ],
    buttonLabel: "Close",
  },
  hasVulnerablePersonLabel: "Is someone at this property vulnerable?",
  vulnerableInputLabel: "Text",
  vulnerableTypesList: [
    "Financial vulnerability short term",
    "Financial vulnerability long term",
    "Health",
    "Age",
    "Newborn",
    "Young children",
    "Disability",
    VULNERABILITY_OTHER,
  ],
  vulnerableTextError: "Text under vulnerable other is mandatory",
  vulnerableNoSelectionError:
    "At least one vulnerable check box selection required",
  hasVulnerablePersonSubLabel:
    "We can discuss options to help customers in vulnerable circumstances.",
  hasMedicalDependantLabel:
    "Is someone at this property medically dependent on electricity or piped gas?",
  hasMedicalDependantSubLabel:
    "This means any situation where a loss of electricity or piped gas may result in loss of life or serious harm.",
  medicalDependantDifferentAsAccountHolderLabel:
    "The medically dependent person isn't the account holder",
  medicalDependantFirstNameLabel: "First name",
  medicalDependantFirstNamePlaceholder: "",
  medicalDependantFirstNameMaxLength: 30,
  medicalDependantFirstNameRequiredMsg:
    "Medical dependent First Name is required",
  medicalDependantLastNameLabel: "Last name",
  medicalDependantLastNamePlaceholder: "",
  medicalDependantLastNameMaxLength: 30,
  medicalDependantLastNameRequiredMsg:
    "Medical Dependent Last Name is required.",
  medicalDependantPhoneLabel: "Phone no.",
  medicalDependantPhonePlaceholder: "e.g. 091234567",
  medicalDependantPhoneMaxLength: 11,
  medicalDependantPhoneRequiredMsg:
    "Medical Dependent Phone Number is required.",
  medicalDependantPhoneInvalidMsg: "Invalid phone number",
  medicalDependantEMailAddressLabel: "Email",
  medicalDependantEMailAddressPlaceholder: "",
  medicalDependantEMailAddressMaxLength: 50,
  medicalDependantEMailAddressRequiredMsg:
    "Medical Dependent Email is required.",
  medicalDependantEMailAddressInvalidMsg: "Invalid Email",
  criticalMedicalEquipmentLabel: "Details of the critical medical equipment",
  criticalMedicalEquipmentPlaceholder: "",
  criticalMedicalEquipmentRequiredMsg:
    "Critical Medical Equipment is required.",
  criticalMedicalEquipmentMaxLength: 50,
  contactHealthPractitionerLabel:
    "I authorise Contact Energy to contact my health practitioner to collect information about me for this purpose",
  contactHealthPractitionerSubLabel:
    "We require your permission to contact your health practitioner directly, so we can verify your medically dependent status. We'll need to re-confirm this status every year.",
  practiceNameLabel: "Practice name",
  practiceNamePlaceholder: "",
  practiceNameMaxLength: 40,
  practiceNameInvalidMsg: "Invalid Name",
  practiceNameRequiredMsg: "Practice name is required.",
  practitionerNameLabel: "Practitioner name",
  practitionerNamePlaceholder: "",
  practitionerNameMaxLength: 40,
  practitionerNameInvalidMsg: "Invalid Name",
  practitionerNameRequiredMsg: "Practitioner name is required.",
  practicePhoneLabel: "Practice phone no.",
  practicePhonePlaceholder: "e.g. 091234567",
  practicePhoneMaxLength: 20,
  practicePhoneInvalidMsg: "Invalid phone number",
  practicePhoneRequiredMsg: "Practice phone number is required",
};

export const Property = {
  cardTitle: "About your property",
  addressLabel: "Address you are signing up",
  addressMaxLength: 250,
  addressRequiredMsg: "Street address is required",
  addressDifferentAsPostalAddressLabel: "I have a different postal address",
  postalAddress: "Postal address",
  postalAddressPlaceHolder: "",
  postalAddressMaxLength: 250,
  postalAddressRequiredMsg: "Address is required",
  datePlaceholder: "DD / MM / YYYY",
  dateFormat: DATE_FORMAT,
  situationLabel: "What is your situation?",
  creditCheckTitle: "Credit check",
  buttonCreditCheck: "Run credit check",
  amber: "Amber",
  red: "Red",
  defaultBond: "N/A",
  amberBond: "150",
  prepayName: "PREPAY",
  paymentCycleWF: "WEEKLY/FORTNIGHTLY",
  ccServiceElec: "ELEC",
  ccServiceGasNI: "NGAS",
  ccServiceGasSI: "RLPG",
  situationRadioButton: [
    {
      radioButtonId: "NOTMOVING",
      radioButtonCode: SITUATION_NOT_MOVING,
      radioButtonLabel: "I am changing energy supplier (not moving house)",
      serviceSwitchDateLabel: "The date you want your services switched on",
      serviceSwitchCustomerDateLabel:
        "Please note, delays can sometimes occur and your final switch date will be confirmed once it's all set up.",
      serviceSwitchDateRequiredMsg: "This field is required",
      serviceSwitchDateLaterMsg: "Please pick a later date",
    },
    {
      radioButtonId: "MOVING",
      radioButtonCode: SITUATION_MOVING,
      radioButtonLabel: "I am moving, or have already moved house",
      serviceSwitchDateLabel: "The date you want your services switched on",
      serviceSwitchDateRequiredMsg: "Move-in date cannot be left blank",
      serviceSwitchDateLaterMsg: "Please pick a later date",
    },
    {
      radioButtonId: "OWNERSHIP",
      radioButtonCode: SITUATION_CHANGE_OWNERSHIP,
      radioButtonLabel:
        "I’m taking ownership of an existing Contact Energy account ",
      serviceSwitchDateLabel: "The date you want account ownership changed",
      serviceSwitchDateRequiredMsg: "This field is required",
      serviceSwitchDateLaterMsg: "Please pick a later date",
      situationOtherDetails: "Please tell us about your situation",
      situationOtherDetailsMaxLength: 200,
      situationOtherDetailsRequiredMsg:
        "Please explain your situation so we can help you",
      situationOtherDetailsPlaceHolder:
        "e.g. Looking to take over the flat electricity account as the flatmate moving out is the primary account holder.",
    },
  ],
  buttonLabel: "Continue",
  hazards: {
    common: {
      optionsDog: "Dog",
      dogHazards: "Details on dog hazards",
      dogHazardsRequiredMsg: "Dog details are required.",
    },
    electricityOrPipeGasOnly: {
      label: "Potential hazards or access issues at my property",
      helpText:
        "We need this information just in case a meter reader needs to visit.",
      optionsOther: "Other",
      optionsNoHazards: "No hazards",
      options: ["No hazards", "Dog", "Other"],
      otherHazards: "Other hazards not mentioned above",
      otherHazardsPlaceholder: "",
      otherHazardsRequiredMsg: "Hazard details are required.",
    },
    bottleGasWithElectricity: {
      label: "Potential hazards or access issues at my property",
      helpText:
        "We need this information just in case a meter reader needs to visit and when we deliver your gas bottles.",
      optionsOther: "Other",
      optionsNoHazards: "No hazards",
      options: [
        "No hazards",
        "Dog",
        "Steps",
        "Locked gate",
        "Steep driveway",
        "Slippery path",
        "Difficult street parking",
        "Property on a hill",
        "Other",
      ],
      otherHazards: "Other hazards not mentioned above",
      otherHazardsPlaceholder:
        "E.g.: Dog is running free or meter is inside the house",
      otherHazardsRequiredMsg: "Hazard details are required.",
    },
    bottleGasOnly: {
      label: "Potential hazards or access issues at my property",
      helpText:
        "We need this information for when we deliver your gas bottles.",
      optionsOther: "Other",
      optionsNoHazards: "No hazards",
      options: [
        "No hazards",
        "Dog",
        "Steps",
        "Locked gate",
        "Steep driveway",
        "Slippery path",
        "Difficult street parking",
        "Property on a hill",
        "Other",
      ],
      otherHazards: "Other hazards not mentioned above",
      otherHazardsPlaceholder: "",
      otherHazardsRequiredMsg: "Hazard details are required.",
    },
  },
  continueUsingKeysLabel:
    "Contact currently hold a key or keys for this property for the purpose of reading the meter. Are you happy for us to continue using the key(s)?",
  previewAddress: "Address: ",
  previewPostalAddressSameAsPropertyAddress: "Same as supply address",
  previewPostalAddress: "Postal Address: ",
  previewMySituation: "My situation: ",
  previewServiceSwitchDate: "Services switched on: ",
  previewHazards: "Hazards: ",
  moveOutProperty: "<b>Move-out property</b>",
  moveOutPropertyLabel: "Your current property address",
  moveOutDate: "Move-out date for current property",
  moveOutDateLabel: "The date you want to move-out from current property",
  moveOutDateRequiredMsg: "Move-out date can not be left blank",
  moveOutHaveFinalMeterReadingTitle: "Do you have a final meter reading?",
  moveOutHaveFinalMeterReadingDescription:
    "Meter reading for Electricity and/or Piped gas.",
  moveOutHaveFinalMeterReadingOptions: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  moveOutFinalMeterReadDateFormat: "DD MMM YYYY",
  moveOutFinalMeterReadListTitle: {
    electricity: "<b>Electricity</b>",
    gas: "<b>Piped Gas</b>",
  },
  moveOutFinalMeterReadTakenDaysSince: " days ago",
  moveOutFinalMeterReadItemLabels: {
    meterType: "Meter type: ",
    meterTypeStandard: "Standard",
    meterTypeSmart: "Smart Meter",
    lastRead: "Last read: ",
    serialNumber: "Serial number: ",
    nextPlannedRead: "Next planned read: Approx. ",
    meterReading: "Meter reading: ",
    meterReadingTaken: "Taken ",

    deviceId: "Device ID: ",
    registerId: "Register ID: ",
  },
  moveOutFinalMeterReadInputs: {
    electricity: {
      placeholder: "Enter final electricity meter reading",
      maxLength: 30,
      requiredMessage: "Electricity meter read can not be left blank",
      invalidMessage: "Electricity meter read must be a number",
    },
    gas: {
      placeholder: "Enter final gas meter reading",
      maxLength: 30,
      requiredMessage: "Gas meter read can not be left blank",
      invalidMessage: "Gas meter read must be a number",
    },
  },
  moveOutSendMeterReaderLabel: "I want meter reader to be sent.",
  moveOutSendMeterReaderDescription:
    "There will be a $35 charge for the meter reader.",
  moveOutSupplyOwnMeterReadByPhoneOrEmailLabel:
    "I'll supply my own meter reading(s) by phone or email.",
  moveInProperty: "<b>Move-in property</b>",
  moveInPropertyLabel: "Address you are moving to",
  moveInDate: "Move-in date for new property",
  moveInDateLabel: "The date you want to move-in to the new property",
  moveInDateRequiredMsg: "Move-in date can not be left blank",
  moveInDateMonthRestriction: 2,
};

export const BottledGas = {
  cardTitle: "About your bottled gas order",
  sectionResidentialDisclaimer:
    "Contact and Rockgas will both be looking after your LPG supply. Rockgas will deliver your replacement " +
    "bottles and we're responsible for ordering, billing and customer help.",
  numberOfBottles: "How many 45kg bottles would you like to order?",
  numberOfBottlesPreview: "Number of LPG bottles",
  numberOfBottlesDescription:
    "A minimum of 2 bottles is required for your first order and delivery is subject to a site inspection.",
  numberOfBottlesOptions: [
    { value: "0", label: "None" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5+", label: "5+" },
  ],
  numberOfBottlesDefault: "0",
  amountDisclaimer:
    "If you store more than 100kgs of bottled gas, we can only deliver to you if your site meets " +
    '<a href="https://contact.co.nz/support/guides-staying-safe#Staying-safe-with-gas" target="_blank" rel="noopener noreferrer">certain criteria</a>.',
  differentPostalAddress:
    "The delivery address is different to my postal address above",
  deliveryAddress: "Delivery address",
  deliveryAddressPlaceholder: "Enter your address",
  deliveryAddressErrorMessage: "Address is required",
  deliveryAddressInvalidMessage: "Address is invalid",
  paperlessStatements:
    "I would like to receive my bottled gas bills and correspondence by email (where available)",
  paperlessStatementsDescription:
    "If your bottled gas is supplied by one of our Franchises, then email may not be available at this time. " +
    "Your franchise will confirm that.",
  paperlessStatementsDefault: false,
  deliveryInstructions: "Special delivery instructions",
  deliveryInstructionsMaxLength: 200,
  previewYourPostalAddress: "Your Postal address",
};

export const Broadband = {
  cardTitle: "About your broadband order",
  broadbandSubLabel: "We'll start working on your order right away.",
  broadbandDateDescription:
    "Don't need broadband right away? Let us know the start date below:",
  broadbandDateRequired: "This field is required",
  currrentBroadbandProviderLabel: "Current broadband provider",
  hasBroadbandProviderLabel: "I currently have a broadband provider",
  broadbandProviderLabel: "Current broadband provider name",
  broadbandProviderRequiredMsg: "Broadband provider name is required",
  broadbandProviderMaxLength: 50,
  accountContact: "Contact",
  accountHolderNameLabel: "Account holder's name",
  accountHolderNameRequiredMsg: "Account holder name is required",
  accountHolderNameLabelMaxLength: 30,
  accountNumberLabel: "Broadband account no.",
  accountNumberRequiredMsg: "Broadband account number is required",
  accountNumberInvalidMsg: "Invalid broadband account number",
  accountNumberMaxLength: 20,
  modemContactHeader: "Contact Modem",
  modemDeliveredToDifferentAddressLabel:
    "Please send my modem to a different address",
  modemDeliveredToDifferentAddressSubLabel:
    "Modem included ($14.99 postage and handling applies)",
  deliveryAddressLabel: "Delivery address",
  deliveryAddressRequiredMsg: "Modem delivery address is required",
  addPhoneLineLabel:
    "Add a telephone line to my Broadband plan for an additional $10 per month",
  addPhoneLineMainLabel:
    "Adding a telephone line means you’ll need to buy or rent a Contact modem.",

  addPhoneLineSubLabel: ` If there’s a power outage, you won't be able to use this telephone line to make 111 calls. If you don't have any other way to make 111 calls and someone at your property is at risk of needing emergency services, you may be eligible for support under the 111 Contact Code. See <a href="https://contact.co.nz/voip111" target="_blank" rel="noopener noreferrer">here</a> for details.`,
  homePhoneNumberLabel:
    "Please enter your current phone number (If you choose to leave this blank, we will create a new one for you)",
  homePhoneNumberPlaceholder: "e.g. 091234567",
  homePhoneNumberInvalidMsg: "Invalid home number",
  homePhoneNumberRequiredMsg: "Current home number is required",
  phoneLineOptionsLabel: "Optional add-ons for your phone line",
  addonsOptions: [
    {
      code: "INTERNATIONAL",
      label:
        "100 hours calling to top 10 countries: Australia, UK, USA, Canada, Ireland, South Africa, South Korea, India, Hong Kong and China",
    },
    {
      code: "MOBILE100",
      label: "Landline to Mobile call 100 minutes",
    },
    {
      code: "MOBILE200",
      label: "Landline to Mobile call 200 minutes",
    },
  ],
  buttonLabel: "Continue",
  previewBroadbandDate: "Broadband start date:",
  previewBroadbandProvider: "Broadband provider: ",
  previewAccountHolderName: "Account holder name: ",
  previewAccountNumber: "Broadband account number: ",
  previewDeliveryAddress: "Modem delivery address: ",
  previewHomePhoneNumber: "Telephone line number: ",
  previewPhoneLineOptions: "Add-ons: ",
  providerList: [
    "2degrees",
    "Actrix",
    "Bigpipe",
    "Blue Door",
    "CallPlus",
    "Clearnet",
    "Compass",
    "Farmside",
    "Flip",
    "ICONZ",
    "Inspire.net",
    "Kiwilink",
    "MyRepublic",
    "NetSpeed",
    "Nova",
    "Now",
    "NZFarming",
    "Orcon",
    "Skinny",
    "Slingshot",
    "Spark",
    "Stuff Fibre",
    "Terrible Talk",
    "Trustpower",
    "Ultrafast Fibre Ltd",
    "Unlimited Internet",
    "Vodafone",
    "Voyager",
    "Wireless Nation",
    "Woosh",
    "Worldnet",
  ],
  datePlaceholder: undefined,
  modemSupplierLabel: "Modems",
  modemSupplierOptions: [
    {
      value: "BYO",
      label: "Bring your own modem",
    },
    {
      value: "Contact",
      label: "I need a Contact modem",
    },
  ],
  modemSupplierBYODescription:
    "Connections usually take around five business days if your place is fibre ready and not already connected with another provider. Otherwise, it may take a minimum of ten business days.",
  modemSupplierBYOAndContactModemDisabledDescription:
    "New broadband connections are only available currently if you Bring Your Own (BYO) modem.",
  modemSupplierContactDescription:
    "Connections usually take around 5 business days if your place is fibre ready and not already connected with another provider. Otherwise, it may take a minimum of 10 business days.",
  modemSupplierContactWirelessDescription:
    "Connections usually take around 5 business days. It will be ready to go by the time you receive your Contact wireless modem.",
  modemMakeModalTitle: "Supported modems at Contact",
  modemMakeDescriptionPrefix: "We find ",
  modemMakeDescriptionLinkLabel: "most modems will work",
  modemMakeDescriptionPostfix:
    " with Contact broadband though you may need to update the settings. If you’re buying a modem, please check first to ensure it will work for fibre and is compatible with Contact broadband.",
  contactModemAvailabilityFeatureFlag:
    "customer-journeys-contact-modem-availability",
  sendContactModemConnected:
    "We’ll send you a new Contact modem and you’re good to go.",
  sendContactModemNotConnected: "We’ll send you a new Contact modem.",
  modemPostageApplies: "$14.99 postage and handling applies.",
  fibreInstallRequired:
    "Looks like you might need Fibre installed at your property. Connections usually take a minimum of 10 business days.",
  fibreInstalled:
    "Connections usually take around 5 business days if your place is fibre ready and not already connected with another provider. Otherwise, it may take a minimum of 10 business days.",
  byoModemTooltip:
    "Please note, we're unable to offer a VOIP (phone) connection with a BYO modem.",
  byoModemTooltipWireless:
    "Sorry, bring your own modem (BYOM) is not available for Wireless connections.",
};

export const Modem = {
  cardTitle: "About your modem",
  broadbandSubLabel: "Do you need a modem?",
  broadbandTeleSubLabel:
    "Adding a telephone line means you'll need to rent or buy a Contact modem.",
  optionBYOM: "No thanks, I will use my own modem",
  optionBYOMDescription: "Check if your modem is compatible",
  optionContactModem: "Yes please, I'd like to rent or buy a ",
  optionContactModem2: ", which could be new or refurbished",
  wirelessModem:
    "Choosing a wireless broadband connection means you'll need to rent or buy a ",
  wirelessModem2: ", which could be new or refurbished.",
  optionContactModemClickable: "Contact modem",
  deliveryAddressLabel: "Delivery address",
  paymentTitle: "How would you like to pay for your modem?",
  rentLabel1: "Rent a modem for $",
  rentLabel2: " per month ongoing, payable on each broadband bill",
  rentTooltip:
    "If you decide later that you would like to either buy a Contact modem or switch to using your own modem, please check our modem FAQs to see how to do this.",
  buyLabel1: "Buy a modem for $",
  buyLabel2: ", payable on my first broadband bill",
  postageLabel1: "Postage and handling fee of",
  postageLabel2:
    "will be included on your first broadband bill for either option.",
  gst: "All amounts include GST",
  differentAddress: "Please send my modem to a different address",
  summaryFibre: "Fibre modem: ",
  summaryCopper: "ADSL/VDSL modem: ",
  summaryByom: "Using my own modem",
  summaryContactFibre: "Fibre modem: ",
  summaryContactCopper: "ADSL/VDSL modem: ",
  summaryContactWireless: "Wireless modem: ",
  summaryRentingLabel1: "Renting a modem for $",
  summaryRentingLabel2: "per month ongoing, payable on each broadband bill",
  summaryBuyingLabel1: "Buying a modem for $",
  summaryBuyingLabel2: ", payable on my first broadband bill",
  summaryDeliveryAddress: "Delivery address:",
  modemMakeModalTitle: "Supported modems at Contact",
};

export * from "./TypedConstants";
export const DIRECT_DEBIT_KEEP_EXISTING = "Keep Existing Direct Debit";
export const DIRECT_DEBIT_UPDATE_BANK_ACCOUNT =
  "Update Direct Debit Bank Account";

export const Payment = {
  cardTitle: "How you’ll pay",
  cardIntro: "Choose to be billed regularly",
  cardIntroWithPrepay:
    "Choose to be billed regularly, or go onto Contact PrePay",
  paymentMethodToolTip:
    "Sorry, PrePay is not available if you have a standard meter, more services than just ‘electricity’ or on certain plans.",
  cardIntroDisclaimer:
    "When you select Contact PrePay, you will receive correspondence and bills by email.",
  paymentMethods: [
    { value: PAYMENT_METHOD_BILL, label: "Get a regular bill" },
    { value: PAYMENT_METHOD_PREPAY, label: "Contact PrePay" },
  ],
  accountNumberMinLength: 15,
  smartFuelFieldLength: 19,
  paymentMethodDefault: PAYMENT_METHOD_BILL,
  billingCycle: "I would like to be billed",
  billingCycleText: "You can revise this later if you change your mind",
  billingCycleOptions: [
    { value: BILLING_CYCLE_MONTHLY, label: "Monthly" },
    { value: BILLING_CYCLE_FORTNIGHTLY, label: "Fortnightly" },
    { value: BILLING_CYCLE_WEEKLY, label: "Weekly" },
  ],
  billingCycleDefault: BILLING_CYCLE_MONTHLY,
  billStartDate: "When would you like to start receiving your bills from?",
  billStartDatePreview: "Start receiving bills on",
  billingCycleDisclaimer:
    `By selecting weekly or fortnightly billing you agree to these ` +
    `<a href="${periodTermsAndConditions}" download rel="noopener noreferrer">terms and conditions</a>`,
  sectionDisclaimer:
    "NOTE: Any discounts only apply towards our electricity and natural gas usage.",

  paperlessDiscountDefault: true,
  paperlessDiscount:
    "I would like to receive my bills and correspondence by email",
  paperlessDiscountText:
    "Choose to receive your bills by email and avoid the $2.30 paper bill fee. Find out about service fees " +
    '<a href="https://contact.co.nz/support/prices-and-fees#Service-fees" target="_blank" rel="noopener noreferrer">here</a>.',
  paperlessDiscountPreview: "Email billing",
  noPaperlessDiscountMessage:
    "Choose YES, if you would like weekly or fortnightly billing options on this plan.",
  joinDirectDebitDefault: false,
  joinDirectDebit: "I would like to set up a Direct Debit",
  noJoinDirectDebitMessage:
    "Choose YES, if you would like weekly or fortnightly billing options on this plan. You can set up your " +
    "preferred payment method once we've activated your My Account login.",
  joinDirectDebitText:
    "Paying by direct debit from a bank account means avoiding over the counter or credit/debit card fees. " +
    'Find out about service fees <a href="https://contact.co.nz/support/prices-and-fees#Service-fees" target="_blank" rel="noopener noreferrer">here</a>.',
  joinDirectDebitPreview: "Set up direct debit",
  bankAccountNumber: "Bank account no.",
  bankAccountNumberErrorMessage: "Your bank account is required",
  bankAccountNumberInvalidMessage: "Please check bank account you have entered",
  agreeWithTermsAndConditions:
    "Just confirming you are able to act as the sole signatory for the bank account provided and agree to the direct debit " +
    `<a href="${ddTermsAndConditions}" download rel="noopener noreferrer">terms and conditions.</a>`,
  prePayMobileNumber: "Your mobile no.",
  prePayMobileNumberErrorMessage: "Your mobile number is required",
  prePayMobileNumberInvalidMessage:
    "Please check mobile number you have entered",
  prePayMobileNumberMaxLength: 20,
  prePayMobileNumberDisclaimer:
    "We will use this mobile no. to alert you when your balance is low. There are no SMS charges for notifications.",
  prePayMobileNumberAgree:
    "I agree to receive all notifications by text to the mobile number provided, some email correspondence and I agree to the Contact PrePay " +
    `<a href="${prepayTermsAndConditions}" download rel="noopener noreferrer">terms and conditions</a>.`,
  buttonLabel: "Continue",
  verificationFailedModal: {
    bankAccountNumber:
      "Apologies, we’re unable to process your Bank account number details. You can join Direct Debit " +
      "after your signup is complete.",
    buttonLabel: "Close",
  },
  weeklyFortnightlyUnavailableHeader: "About your billing set up.",
  weeklyFortnightlyUnavailableText: `
    We're sorry to say that we're unable to support weekly or fortnightly billing
    in your new property. This could be because of the meter type at the new
    property or the services you have chosen. We can still supply your services
    you will be billed monthly.`.trim(),
  directDebitOptions: [
    {
      value: DIRECT_DEBIT_KEEP_EXISTING,
      label: "Transfer my existing direct debit to new property",
    },
    {
      value: DIRECT_DEBIT_UPDATE_BANK_ACCOUNT,
      label: "I will supply a new bank account",
    },
  ],
  directDebitExisting: {
    bankAccountLabel: "Existing bank account",
    bankAccountPlaceholder: "Select existing bank account",
  },
};

export const FinalStep = {
  cardTitle: "Final step",
  dateFormat: "dddd D MMM",
  termsAndConditionsPrefix:
    "Before I confirm your new signup, are you happy to accept ",
  termsAndConditionsSuffix:
    "and give me permission to sign you up to receive the products and services you have requested.",
  generalTermsAndConditions: {
    label: "the general",
    labelWithLink: "terms and conditions",
    file: generalTermsAndConditions,
  },
  planTermsAndConditions: {
    label: "the plan",
    labelWithLink: "terms and conditions",
    file: planTermsAndConditions,
  },
  broadbandTermsAndConditions: {
    label: "the broadband",
    labelWithLink: "terms and conditions",
    file: broadbandTermsAndConditions,
  },
  bottledGasTermsAndConditions: {
    label: "the Rockgas",
    labelWithLink: "terms and conditions",
    file: bottleGasTermsAndConditions,
  },
  pipedGasTermsAndConditions: {
    label: "the Rockgas",
    labelWithLink: "terms and conditions",
    file: pipedGasTermsAndConditions,
  },
  buttonLabel: "JOIN CONTACT",
  summaryTitle: "<b>Summary</b>",
  summaryContent:
    "You requested <b>%services%</b> on the <b>%planName%</b> plan to start on <b>%date%</b> at",
  summaryContentWithDifferentBBDate: `You requested <b>%servicesWithoutBB%</b> on the <b>%planName%</b> plan to start on <b>%date%</b> at
   <br/>
   You requested your <b>broadband</b> connection from <b>%broadbandConnectionDate%</b>.`,
  consumerCareTitle: "<b>Consumer Care Policy</b>",
  consumerCareContent: `<span>We have a <a href="${consumerCarePdfUrl}" download rel="noopener noreferrer">Consumer Care Policy</a> which outlines our commitment to you. It includes information such as:</span>
    <ul>
        <li>Payment options to find the one that suits you best</li>
        <li>How to add an alternate contact, support person or authorised person to help service your account</li>
        <li>Contact details for UDL and Powerswitch for independent complaint or pricing advice</li>
    </ul>`,
  privacyStatementTitle: "<b>Privacy Statement</b>",
  privacyStatementContent:
    "<span>We collect the information requested in order to supply you with the products and services you have asked for and if the information isn’t provided, we may not be able to supply you. You have the right to ask for a copy of any personal information we hold about you and to ask for it to be corrected if you think it is wrong.</span>",
  termsAndConditionsTitle: "<b>Terms and Conditions</b>",
  welcomePackContent:
    "<span>We will send all Tc&Cs with your welcome pack and you can also view these on our website.</span>",
};

export const MyDetails = {
  journeyType: "Join",
  header: "My Details",
  pageTitle: "Contact Energy | My Details",
  title: "What you might need to complete",
  intro: "Average time to complete: 3 mins",
  redirectOnErrorUrl: process.env.NX_IJOIN_START_URL,
  backToJourneyText: "EDIT MY ORDER",
  backToJourneyUrl: process.env.NX_IJOIN_START_URL,
  steps: [
    {
      title: "Proof of identity ",
      icon: <AppUserUnknown />,
      html:
        "<p>If you hold a NZ drivers license, keep it handy as it's helpful for credit checking.</p>",
    },
    {
      title: "Bank account details",
      icon: <AppBank />,
      html:
        "<p>If you choose to setup Direct Debit payment, keep your bank account details handy. " +
        "Paying by direct debit from a bank account is one way to avoid over the counter or credit/debit card fees.</p>",
    },
    {
      title: "Important dates",
      icon: <AppCalendar />,
      html:
        "<p>If you're on the move, let us know your new address, your move dates and when you want our services to start.</p>",
    },
  ],
  joinErrorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
};

export const FindPlan = {
  journeyType: "Join",
  newBuildUrl: NEW_BUILD_URL,
  yourAddressTitle: "What’s your street address?",
  serviceTitle: "Which services would you like?",
  planTitle: "Choose your new plan",
  gasTitle: "What will you be using bottled gas for?",
  bbTitle: "Which broadband type would you like?",
  elecNeededPipedGasNote:
    "To sign up to our Piped Gas service you must have your Electricity account at the same address.",
  elecNeededPipedGasRlpgNote:
    "To sign up to our piped LPG service online, you need an electricity account with us at the same address.  To sign up for our piped LPG service without electricity, please call 0800 80 9000.",
  elecNeededNote:
    "To sign up to our Broadband service you must have your Electricity account at the same address.",
  serviceEmptyNote: "Please select your service.",
  onlyLPGNote:
    "You do not need to select a plan to order bottled gas, please continue to the next question.",
  onlyGasSANote:
    "You don't need to select any plan with Piped Gas as only service for South Island addresses.",
  cookingGasNote:
    "Unfortunately, we are unable to supply bottled gas if gas will be used for cooking only.",
  bbAddNote:
    "We have added BroadBand service into your selection since is required on this plan.",
  bbRemoveNote:
    "We have removed your selected plan since BroadBand is required on this plan.",
  gasAddNote:
    "We have added Piped gas service into your selection since is required on this plan.",
  simplicityRemoveNote:
    "We have removed your selected plan since Piped Gas is required on this plan.",
  southRemoveNote:
    "We have removed your selected plan since it is not available in South Island.",
  basicPlan: "BASIC",
  simplicityPlan: "SIMPLICITYBUNDLE",
  gasRatesDayUnit: "day",
  lpgOnlyPlan: "LPG-ONLY",
  bbBundle: "BB_BUNDLE",
  rockGas: "ROCKGAS",
  addressRequired:
    "Your street address and services are required to calculate your rates",
  serviceAddress: {
    addressRequiredMsg: "Address is required",
    addressInputLabel: "Enter your street address",
    broadbandAddressInputLabel: "Enter address details here:",
  },
  serviceButtons: [
    {
      name: "electricity",
      code: "ELEC",
      icon: <AppElectricity />,
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "broadband",
      code: "BB",
      icon: <AppBroadbandHome />,
      bbg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "piped gas",
      code: "GAS",
      icon: <Gas />,
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "bottled gas",
      code: "LPG",
      icon: <AppBottledGas />,
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
  ],
  gasButtons: [
    {
      name: "heating",
      code: "heating",
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "hot water",
      code: "hot water",
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "cooking",
      code: "cooking",
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
  ],
  bbDefault: [
    {
      BundlePrice: "$89.99/mth",
      DownloadSpeed: "10",
      Id: "ADSL",
      Label: "",
      Name: "ADSL",
      OriginalPrice: "$89.99/mth",
      UploadSpeed: "2",
    },
    {
      BundlePrice: "$89.99/mth",
      DownloadSpeed: "70",
      Id: "VDSL",
      Label: "",
      Name: "VDSL",
      OriginalPrice: "$89.99/mth",
      UploadSpeed: "15",
    },
    {
      BundlePrice: "$89.99/mth",
      DownloadSpeed: "100",
      Id: "FASTFIBRE",
      Label: "Best for families",
      Name: "Fast Fibre",
      OriginalPrice: "$89.99/mth",
      UploadSpeed: "20",
    },
    {
      BundlePrice: "$114.99/mth",
      DownloadSpeed: "200",
      Id: "FASTERFIBER",
      Label: "Best for renters",
      Name: "Faster Fibre",
      OriginalPrice: "$114.99/mth",
      UploadSpeed: "20",
    },
    {
      BundlePrice: "$129.99/mth",
      DownloadSpeed: "1000",
      Id: "MAXFIBRE",
      Label: "Best for streamers",
      Name: "Max Fibre",
      OriginalPrice: "$129.99/mth",
      UploadSpeed: "500",
    },
  ],
  bbCarouselConstants: {
    download: "Mbps download",
    upload: "Mbps upload",
    button: "Choose",
  },
  planCarouselConstants: {
    buttonBBColor: "#000000",
    goodNightsPlanId: "FREENIGHTS",
    goodNightsModalHeader: "This plan isn’t available for your address.",
    goodNightsModalContent:
      "<p>We’re sorry but Distributed Generation (i.e. solar) customers aren’t able to sign up for the Good Nights plan. Please choose a different plan.</p>",
  },
  bbPlanId: "BB_BUNDLE",
  joinRates: {
    id: "expressRates",
    rateLabels: {
      [SERVICE_ID_ELECTRICITY]: ["Your electricity rates", "Electricity rates"],
      [SERVICE_ID_PIPED_GAS]: ["Your piped gas rates", "Gas rates"],
      [SERVICE_ID_BROADBAND]: ["Your broadband rates", "Broadband rates"],
    },
    messages: {
      noAddress:
        "Your street address and services are required to calculate your rates",
      error:
        "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
        "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
      multipleRates: "There are multiple rates for your Property",
      noElectricityRates:
        "Unfortunately we’re unable to provide you with Electricity Rates for this address. You can still complete your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
      noGasRates:
        "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
        "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
      expand: "View details",
      collapse: "Hide details",
      electricityLabel: "Electricity",
      gasLabel: "Piped gas",
      standardUserRates: "standard user rates",
      lowUserRates: "low user rates",
      disclaimer:
        "Energy rates are before GST and any applicable discounts and are based on the plan you selected (or " +
        "our Basic plan if you haven’t chosen one yet). Electricity rates are based on the information available " +
        "for your property, and natural gas rates are the most common in your region. If your property has unmetered load, rates will be confirmed in your welcome letter. We do our best to provide accurate rates, but there are rare " +
        "occasions where your actual prices may differ and we will confirm these once we have worked through all " +
        "the details.",
      usageTypeSwitch: "Switch to %usageType% user",
    },
  },
  noIcp:
    "Unfortunately we`re unable to provide you with an estimate for this address. You can still complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate.",
  detailsTitle: "Basic",
  description:
    "Move house faster with this simple, hassle-free plan. Get transparent pricing plus the flexibility of no fixed term and no break fees.",
  includeTitle: "Express move with the Basic plan includes:",
  gasModule: {
    icons: [
      {
        image: electricityImage,
        text: "Electricity only",
      },
      {
        image: smileImage,
        text: "No fixed term, no break fees, no hassle",
      },
      {
        image: pricintgImage,
        text: "Simple, transparent pricing",
      },
      {
        image: calentarImage,
        text: "Change your plan or add services anytime after your move",
      },
    ],
    planCard: {
      banner: "Change plan anytime",
      tag: "Popular",
      content: {
        plan: "Basic",
        header: "Simple",
        title: " energy rates",
        description: "No fixed term",
      },
    },
  },
  excludedBBType: "ADSL",
  primaryBBType: "FIBRE",
  joinErrorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
};

export const Summary = {
  header: "Summary",
  sideBarHeader: "Why Contact",
  sideBarList: [
    "No need to talk to your old supplier, we'll let them know",
    "Extended New Zealand based call centre hours. From 7am to 9pm, Monday to Friday, and 8am to 5pm on Saturdays.",
    "Over 80% of our generation is from renewable sources",
    "Track your usage and pay bills with our simple, easy-to-use app.",
  ],
  redirectOnErrorUrl: process.env.NX_IJOIN_START_URL,
  goBackText: "back to plan select",
  backToUrl: process.env.NX_IJOIN_START_URL,
  addressTitle: "Your street address",
  planTitle: "Your plan details",
  serviceTitle: "Your service and rates",
  broadbandTitle: "Broadband ",
  electricityTitle: "Electricity ",
  bottledGasTitle: "Bottled gas ",
  bottledGasDescription: "Delivery service",
  pipedGasTitle: "Piped gas ",
  pipedGasDescription: "Reticulated LPG",
  priceLabel: "Price per month",
  standardUser: "standard user",
  lowUser: "low user",
  promoCodeTitle: "Got a promo code?",
  promoCodePlaceholder: "Promo code",
  promoCodeButtonText: "Apply",
  promoCodeButtonTextHover: "Validate",
  promoCodeButtonTextApplied: "Applied",
  promoCodeNotApplicable:
    "Sorry, promo code does not apply to this service or plan.",
  emailTitle: "Please enter your email address",
  emailSubTitle:
    "We may contact you to ensure you complete your signup successfully",
  emailPlaceholder: "Email address",
  emailValidError: "Not valid email",
  emailEmptyError: "Email required",
  buttonText: "Continue to my details",
  buttonSubText: "3 - 4 minutes to complete",
  joinRates: {
    id: "joinRates",
    rateLabels: {
      ELEC: ["Your electricity rates", "Electricity rates"],
      GAS: ["Your piped gas rates", "Gas rates"],
      BB: ["Your broadband rates", "Broadband rates"],
    },
    messages: {
      noAddress:
        "Your street address and services are required to calculate your rates",
      error:
        "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
        "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
      multipleRates: "",
      noElectricityRates:
        "Unfortunately we’re unable to provide you with Electricity Rates for this address. You can still complete your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
      noGasRates:
        "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
        "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
      expand: "View rates",
      collapse: "Close rates",
      electricityLabel: "Electricity",
      gasLabel: "Piped gas",
      standardUserRates: "standard user rates",
      lowUserRates: "low user rates",
      disclaimer: "",
      yourCurrentType: "We think you are a %currentUsageType% user. ",
      usageTypeSwitch: "Switch to %usageType% user rates",
      fixedRates: "fixed rates until ",
      notFixedRates: "No fixed term / No break-up fees",
    },
  },
  ratesDisclaimer:
    "Rates are based on the plan you selected and exclude GST. Electricity rates are based on the information" +
    "available for your property, and gas rates are the most common in your region. (If your property has unmetered load, additional charges will apply.) Broadband prices include GST.",
  emailDomainsList: [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "xtra.co.nz",
    "live.com",
    "mail.com",
    "msn.com",
    "aol.com",
  ],
  excludedPromo: {
    services: ["GAS", "LPG"],
    plansId: ["MONTHOFF", "EDBONUSFIXED"],
  },
  joinErrorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
};

export const CustomerJoin = {
  journeyChannel: "CUSTOMER",
  journeyType: "Join",
  path: "/residential",
  pathEntry: "/find-a-plan",
  pathCustomerDetails: "/my-details",
  pathSuccess: "/success",
  entryPageTitle: "Find the best plan for you - Contact Energy",
  customerDetailsPageTitle: "Contact Energy | My Details",
  analytics: {
    entryPage: undefined,
    entryJourney: "Join",
  },
  featureFlags: {
    billCompare: "customer-journeys-bill-compare",
    billCompareABTesting: "customer-journeys-bill-compare-ab-testing",
    billCompareBroadbandPromoBox:
      "customer-journeys-bill-compare-broadband-promo-box",
    customerPlansABTest: "customer-plans-ab-test",
  },
  continueButton: {
    continueTitle: "Continue",
    continueSubTitle:
      "This should take about &nbsp;<strong>3 - 4 minutes</strong>&nbsp;to complete\n",
  },
  continueButtonLabel: "Continue",
  joinContactButtonLabel: "Join Contact",
  title: "Join us",
  header: undefined,
  backToJourneyUrl: undefined,
  category1: "Registries",
  category2: "Broadband",
  category3: "Bottle Gas",
  noteMaxLength: 512,
  noteRequiredMessage:
    "Please supply interaction record text in the notes field.",
  mailPaperless: "EMAL",
  mailPaper: "PAPR",
  clearSessionRedirectUrl: process.env.NX_SIGN_UP_START_URL,
  clearSession: "Clear and start over",
  newBuildUrl: NEW_BUILD_URL,
  clearSessionModal: {
    primaryButtonContent: "Keep Info",
    secondaryButtonContent: "Delete & start over",
    html:
      '<strong style="font-size:20px;">Are you sure you want to delete this session and start over?</strong>',
  },
  questionsTitle: "Open questions",
  summaryTitle: "Summary",
  findAPlanQuestions: [
    "So I can recommend the correct plan, tell me a bit more about your household.",
    "Lets chat through through your energy needs.",
    "Have you heard about Contact Broadband?",
  ],
  accountNumberMinLength: 15,
  smartFuelFieldLength: 19,
  gasRatesDayUnit: "day",
  bbBundle: "BB_BUNDLE",
  rockGas: "ROCKGAS",
  notesTitle: "Notes",
  notesButton: "Save note",
  planServiceDetails: {
    title: "1. What's your street address?",
    titlePlans: "3. Choose your new plan",
    bbTitle: "Which broadband type would you like?",
    bbBundle: "BB_BUNDLE",
    rockGas: "ROCKGAS",
    basicPlan: "BASIC",
    titleS: "Find address and services",
    simplicityPlan: "SIMPLICITYBUNDLE",
    lpgOnlyPlan: "LPG-ONLY",
    elecNeededPipedGasNote:
      "To sign up to our Piped Gas service you must have your Electricity account at the same address.",
    elecNeededPipedGasRlpgNote:
      "To sign up to our piped LPG service online, you need an electricity account with us at the same address.  To sign up for our piped LPG service without electricity, please call 0800 80 9000.",
    elecNeededNote:
      "To sign up to our Broadband service you must have your Electricity account at the same address.",
    serviceEmptyNote: "Please select your service.",
    onlyLPGNote:
      "You do not need to select a plan to order bottled gas, please continue to the next question.",
    onlyGasSANote:
      "You don't need to select any plan with Piped Gas as only service for South Island addresses.",
    billCompareEligibilityMessage: `Sorry your property isn’t eligible for bill comparison. Expand the 'View rates' section to view your energy rates.`,
    cookingGasNote:
      "Unfortunately, we are unable to supply bottled gas if gas will be used for cooking only.",
    noGasSelectionNote: "Please select your bottle gas usage",
    siteAccessNote:
      "Unfortunately, we are unable to supply bottled gas if your gas bottles are accessed in or through a garage. However if you are unsure, feel free to complete your signup and we'll be in touch to discuss options with you.",
    bbAddNote:
      "The plan you’ve chosen needs broadband so we’ve added this in for you.",
    bbRemoveNote:
      "We have removed your selected plan since BroadBand is required on this plan.",
    gasAddNote:
      "We have added Piped gas service into your selection since is required on this plan.",
    simplicityRemoveNote:
      "We have removed your selected plan since Piped Gas is required on this plan.",
    southRemoveNote:
      "We have removed your selected plan since it is not available in South Island.",
    touRemoved:
      "We have removed your selected plan since a smart meter is required on this plan.",
    distributedGenerationSelected:
      "Your plan selection isn’t available at this address. Please choose a different one.",
    prePayNotAvailable:
      "Please note, PrePay isn’t available on the plan you’ve chosen.",
    billCompareButtonLabel: "Compare your bill",
    billCompareButtonSubLabel: "See if you can save with us",
    defaultOfferingsNote:
      "Sorry, we were unable to confirm your broadband availability. The connection type you select below might change based on availability.",
    gasStatus: [
      {
        connectionStatus: "GAS",
        description: "Gas able to flow",
        status: "ACTC",
      },
      {
        connectionStatus: "GAS",
        description: "Gas able to flow",
        status: "ACTV",
      },
      {
        connectionStatus: "GDE",
        description:
          "Service disconnected from network outside property and service abandoned",
        status: "DECR",
      },
      {
        connectionStatus: "GIR",
        description: "Gas ready to flow",
        status: "READY",
      },
      {
        connectionStatus: "GMM",
        description:
          "Gas maintenance disconnect  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GMC",
        description:
          "Gas maintenance disconnect  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GMU",
        description:
          "Gas maintenance disconnect  GMS remains service disconnected upstream of service valve by network operator",
        status: "INACT",
      },
      {
        connectionStatus: "GNC",
        description:
          "Gas currently not required  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GNM",
        description:
          "Gas currently not required  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GPC",
        description:
          "Gas permanent disconnect ready for GMS removal  GMS remains supply capped or plugged",
        status: "INACP",
      },
      {
        connectionStatus: "GPM",
        description:
          "Gas permanent disconnect ready for decommissioning  GMS removed supply capped or plugged",
        status: "INACP",
      },
      {
        connectionStatus: "GSC",
        description:
          "Gas safety disconnect  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GSM",
        description:
          "Gas safety disconnect  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GSU",
        description:
          "Gas safety disconnect  GMS remains service disconnected upstream of service valve by distributor",
        status: "INACT",
      },
      {
        connectionStatus: "GTD",
        description:
          "Gas temporary disconnect - GMS remains service turned off at service valve or supply capped or plugged",
        status: "ACTC",
      },
      {
        connectionStatus: "GVC",
        description:
          "Gas vacant disconnect  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GVL",
        description:
          "Historic gas transitional disconnect -- GMS removed service turned off and locked at service valve",
        status: "INACT",
      },
      {
        connectionStatus: "GVM",
        description:
          "Gas vacant disconnect  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GVT",
        description:
          "Historic gas transitional disconnect -- GMS remains service turned off at service valve",
        status: "INACT",
      },
      {
        connectionStatus: "NEW",
        description: "Pre-activation service has not yet been installed",
        status: "NEW",
      },
    ],

    newBuildUrl: NEW_BUILD_URL,
    serviceTitle: "2. Which services would you like?",
    services: {
      broadband: {
        networkLinkURL: "https://broadbandmap.nz/",
        networkLinkLabel: "Go to site",
      },
    },
    serviceButtons: [
      {
        name: "broadband",
        code: "BB",
        icon: <BroadBandLogo />,
        bbg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
      // { name: 'piped gas', code: "GAS", icon: <Gas />, bg: '#fff', bgSelected: '#32122f', bgHover: 'rgba(110, 13, 131, 0.05)', bgSelectedHover: '#32122f' },
      {
        name: "bottled gas",
        code: "LPG",
        icon: <BottleGasLogo />,
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
    ],

    gasButtons: [
      {
        name: "Heating",
        code: "heating",
        label: "Heating",
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
      {
        name: "Hot water",
        code: "hot water",
        label: "Hot water",
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
      {
        name: "Cooking",
        code: "cooking",
        label: "Cooking",
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
    ],
    electricityEmbeddedNetworkDesc: {
      YesWithContact: "Yes. ICP is already supplied by Contact.",
      YesWithOtherRetailer: "Yes. Please save as incomplete.",
      No: "No",
    },
    consumptionBandsNorthIsland: ["Low", "Standard"],
    consumptionBandsSouthIsland: ["LCR_01", "LCR_02", "LCR_03"],
    gasUsageTitle: "What will you be using bottled gas for?",
    siteAccess: {
      title: "Site Access",
      message: "Are gas bottles located in or accessed through a garage?",
      options: [
        { name: "Yes", code: "yes", label: "Yes", value: true },
        { name: "No", code: "no", label: "No", value: false },
      ],
    },
    pipedGasDescription:
      "This property is in a <strong>reticulated LPG</strong> area.",
    joinRates: {
      id: "joinRates",
      rateLabels: {
        ELEC: ["Your electricity rates", "Electricity rates"],
        GAS: ["Your piped gas rates", "Gas rates"],
        BB: ["Your broadband rates", "Broadband rates"],
      },
      messages: {
        noAddress:
          "Your street address and services are required to calculate your rates",
        error:
          "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
          "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
        multipleRates: "There are multiple rates for your Property",
        noElectricityRates:
          "Unfortunately we’re unable to provide you with Electricity Rates for this address. You can still complete your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
        noGasRates:
          "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
          "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
        expand: "View rates",
        collapse: "Close rates",
        electricityLabel: "Electricity",
        gasLabel: "Piped gas",
        standardUserRates: "standard user rates",
        lowUserRates: "low user rates",
        disclaimer: "",
        yourCurrentType: "We think you are a %currentUsageType% user. ",
        usageTypeSwitch: "Switch to %usageType% user rates",
        fixedRates: "fixed rates until ",
        notFixedRates: "No fixed term / No break-up fees",
      },
    },
    bbCarouselConstants: {
      download: "Mbps download",
      upload: "Mbps upload",
      button: "Choose plan",
      buttonLink: "learn more",
      byoModemText: "BYO modem available",
    },
    bbPlanId: "BB_BUNDLE",
    planCarouselConstants: {
      buttonBBColor: "#000000",
      goodNightsPlanId: "FREENIGHTS",
      goodNightsModalHeader: "This plan isn’t available for your address.",
      goodNightsModalContent:
        "<p>We’re sorry but Distributed Generation (i.e. solar) customers aren’t able to sign up for the Good Nights plan. Please choose a different plan.</p>",
    },
    promoCodeTitle: "Got a promo code?",
    promoCodePlaceholder: "Promo code",
    promoCodeButtonText: "Apply",
    promoCodeButtonTextHover: "Validate",
    promoCodeButtonTextApplied: "Applied",
    promoCodeNotApplicable:
      "Sorry, promo code does not apply to this service or plan.",
    promoCodeJourneyInvalid:
      "Sorry, this promo code does not apply based on your selections.",
    promoCodeDisclaimer:
      "Discounts only apply towards electricity and natural gas usage.",
    excludedPromo: {
      services: ["GAS", "LPG"],
    },
    lowUser: "Low User",
    low: "Low",
    serviceAreaTitle: "Service Area",
    bottleGasZoneLabel: `<p>Zone: <a href="https://firstgasnz.sharepoint.com/sites/CONT/SitePages/Home.aspx" rel="noopener noreferrer" target="_blank">Check for branch or franchise</a></p>`,
    locationIdLabel: "Location ID",
    locationIdPlaceholder: "",
    locationIdMaxLength: 8,
    locationIdErrorMessage: "Location ID must be 8 digits",
    locationIdLookUpLabel: `<p><a href="${bottleGasLocationNumber}" rel="noopener noreferrer" target="_blank">Look up Location ID:</a></p>`,
    bottleGasPricing: "Pricing",
    bottleGasPricingLookup: `<a href="${bottleGasPricing}" rel="noopener noreferrer" target="_blank">Look up LPG Pricing</a>`,
    bottleGasCylinderPricingLabel: "Cylinder Pricing (Net Rate)",
    bottleGasBottleRentalLabel: "Bottle Rental",
    bottleGasRentalStartDateLabel: "Rental Start Date",
    bottleGasRentalStartDateDescription: "(First of the following month)",
  },
  noBroadbandPlanSelection:
    "Please choose a broadband connection type (e.g. Fibre or Wireless)",
  interactionRecordCode: "7000",
  finalStep: {
    touKeyTermsTitle: "Please note:",
    touKeyTerms: `
      <ul>
        <li>
        There may be times when we need to estimate your electricity usage, for example, where the meter fails to communicate or where you switch off the mains. If this occurs persistently or continuously, we may need to move you to another plan. We'll contact you should this occur.
        </li>
        <li>
        Any estimate we make about your usage will be corrected with actual meter reads data where available and if there's a difference between the estimates and actual reads, we'll invoice or credit you the amount for any differences in calculated charges.
        </li>
      </ul>
    `.trim(),
  },
};

export const PipedGasServiceCard = {
  gasUsageQuestion: "How do you currently use gas in your house?",
  usageTitle: "Usage",
  heating: "Heating",
  cooking: "Cooking",
  water: "Water",
  centralHeating: "Central/underfloor heating",
  consumptionBand: "Consumption band",
  pipedGasButtons: [
    {
      name: "Gas Heating",
      code: "heating",
      label: "Gas Heating",
    },
    {
      name: "Hot Water",
      code: "water",
      label: "Hot Water",
    },
    {
      name: "Cooking",
      code: "cooking",
      label: "Cooking",
    },
    {
      name: "Underfloor",
      code: "centralHeating",
      label: "Underfloor",
    },
  ],
};

export const Success = {
  pageTitle: "Contact Energy | Success",
  greeting: "Thanks %firstName%",
  greetingSubtitle: "We’re glad you’ve decided to join us.",
  greetingExpressSubtitle: "We’re glad you’ve decided to move with us.",
  greetingMoveSubtitle: "We’re glad you’ve decided to move with us.",
  greetingAddSubtitle: "We’re glad you’ve decided to add a property with us.",
  greetingChangeSubtitle: "We're working on your change request",
  redirectOnErrorUrl: process.env.NX_EXPRESS_BASE_URL + "/residential",
  nextStepsTitle: "What happens next?",
  steps: [
    {
      title: "Services",
      icon: <ServiceCircle />,
      html:
        "<p>You requested %services% to start on <b>%startDate%</b> at <b>%address%</b>.</p>",
      required: ["services", "startDate", "address"],
    },
    {
      title: "Services",
      icon: <ServiceCircle />,
      html: "<p>You requested %services%.</p>",
      required: ["services"],
      restricted: ["startDate"],
    },
    {
      title: "Confirmation email",
      icon: <ServiceEnvelope />,
      html:
        "<p>Shortly you'll receive confirmation of your details, and an email to set a password for your online account.</p>",
    },
    {
      title: "Switching",
      icon: <AppHotMugCircle />,
      html:
        "<p>No need to get in touch with your old supplier; we’ll do the switching for you.</p>",
      required: ["isSwitching"],
    },
    // {
    //   title: 'You have a standard meter',
    //   icon: <AppMeter/>,
    //   html: (
    //     '<p>You may need to provide a meter read. ' +
    //     '<a href="https://contact.co.nz/support/meters-and-connections#Standard-meters" target="_blank" rel="noopener noreferrer">View FAQs</a></p>'
    //   ),
    //   required: ['hasElectricity'],
    //   restricted: ['hasSmartMeter'],
    // },
    // {
    //   title: 'You have a smart meter',
    //   icon: <AppMeter/>,
    //   html: (
    //     '<p>No need to supply a meter read as we\'ll read your meter remotely ' +
    //     '<a href="https://contact.co.nz/support/meters-and-connections#Smart-meters" target="_blank" rel="noopener noreferrer">View FAQs</a></p>'
    //   ),
    //   required: ['hasElectricity', 'hasSmartMeter'],
    // },
    {
      title: "PrePay",
      icon: <AppWalletCircle />,
      html:
        '<p>Top-up now through <a href="https://contact.co.nz/account/sign-in" target="_blank" rel="noopener noreferrer">My Account</a>, on our ' +
        '<a href="https://contact.co.nz/residential/billing-and-payments/prepay" target="_blank" rel="noopener noreferrer">website</a>, or the ' +
        '<a href="https://contact.co.nz/support/our-apps" target="_blank" rel="noopener noreferrer">Contact app</a>.</p>',
      required: ["isPaymentPrepay"],
    },
    {
      title: "Bottled gas",
      icon: <AppBottleGasCircle />,
      html:
        "<p>Our team is now processing your request, and will get back to you soon with more information " +
        "on pricing, bottled gas rental fees and delivery date (if applicable).</p>",
      required: ["hasBottledGas", "hasOrderBottledGas"],
    },
    {
      title: "Bottled gas",
      icon: <AppBottleGasCircle />,
      html:
        "<p>Our team is now processing your request, and will get back to you soon with more information " +
        "on pricing, bottled gas rental fees and delivery date (if applicable).</p>" +
        "<p>When you’re ready to order bottled gas:</p>" +
        '<p><a href="https://contact.co.nz/support/contact-us" target="_blank" rel="noopener noreferrer">Give us a call</a> or once your new account is confirmed, order online ' +
        'via our <a href="https://contact.co.nz/support/our-apps" target="_blank" rel="noopener noreferrer">Bottled Gas App.</a></p>',
      required: ["hasBottledGas"],
      restricted: ["hasOrderBottledGas"],
    },
    {
      title: "Broadband",
      icon: <BroadBandCircle />,
      html:
        "You'll receive updates about your new broadband connection soon. For modem support and guides, click <a href='https://contact.co.nz/support/broadband#Modem' target='_blank' rel='noopener noreferrer'>here</a>.",
      required: ["hasBroadband"],
    },
  ],
  bottleGasTitle: "Looking for bottled gas?",
  bottleGasText: `Talk to our trusted LPG partners, Rockgas. From speedy supply to safe installation, they're ready to help. Learn more <a href="https://rockgas.co.nz/" target="_blank" rel="noopener noreferrer">here</a>.`,
  belowNextStepsNote:
    'In the mean time, if you have any further questions please <a href="https://contact.co.nz/support/contact-us" target="_blank" rel="noopener noreferrer">get in touch</a>.',
  tipsTitle: "Helpful tips",
  tipsLabel1: "Need help? Get support",
  tipsLabel2: "Rewards with AA Smartfuel",
  tipsLabel3: "Flexible payment options",
  tipsLink1: process.env.NX_EXPRESS_BASE_URL + "/support",
  tipsLink2: process.env.NX_EXPRESS_BASE_URL + "/residential/rewards",
  tipsLink3:
    process.env.NX_EXPRESS_BASE_URL +
    "/residential/billing-and-payments/weekly-fortnightly",

  nps: {
    title: "Rate your experience with us",
    thanksMsg: "Thanks for messaging with us",
    backButton: "Back",
    continueButton: "Continue",
    asyncFormKey: "nps.async.feedback",

    score: {
      subtitle:
        "How likely are you to recommend Contact Energy to a colleague or a friend?",
      continueButton: "Continue",
    },
    feedback: {
      subtitle: "Why did you score %score%?",
      feedbackLabel: "Type your feedback here",
      backButton: "Back",
      continueButton: "Continue",
    },
    howlong: {
      subtitle:
        "When do you expect a reply to your enquiry in Facebook Messenger or WhatsApp?",
      items: [
        "30 minutes",
        "1-2 hours",
        "Half a day",
        "One day",
        "Two days",
        "Five days",
        "If my query gets resolved, I don’t mind",
      ],
      backButton: "Back",
      continueButton: "Continue",
    },
    contactYou: {
      subtitle:
        "Would you like someone to contact you to discuss your comments?",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    contactDetails: {
      subtitle:
        "Please enter your details and one of our contact centre team will be in touch",
      nameLabel: "Name",
      nameRequiredMsg: "Name is required",
      phoneNumberLabel: "Phone Number",
      phoneNumberPlaceholder: "e.g. 091234567",
      phoneNumberRequiredMsg: "Phone account number is required",
      phoneNumberInvalidMsg: "Invalid home number",
      emailAddressLabel: "Email address",
      emailRequiredMsg: "Email address is required",
      emailInvalidMsg: "Please enter a valid email address",
      backButton: "Back",
      continueButton: "Continue",
    },
    promotionalPurposes: {
      subtitle:
        "Can we use your comment for our promotional purposes? In doing so, we’ll only publish your comment, first name and town",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    preferredChannel: {
      title: "If you couldn’t message us today, what would you have done?",
      subtitle: "Select one",
      channelLabels: [
        "call you",
        "email you",
        "call and email you",
        "try to figure it out myself",
        "nothing",
      ],
      call: "call you",
      email: "email you",
      callEmail: "call and email you",
      myself: "tried to figure it out myself",
      nothing: "nothing",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    asyncAgain: {
      title:
        "Would you use WhatsApp or Facebook Messenger to speak with us again?",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    thankYou: {
      title: "Thanks, we appreciate your feedback.",
      subtitle: "Join our Customer Panel",
      content:
        "Want to help shape the future of Contact Energy? We're launching an online customer panel and we'd love you to be a part of it. You'll get the chance to have your say and we'll reward your feedback with credits on your energy bill.",
      joinButton: "Join the panel",
      redirectURL: "http://surveys.perceptive.co.nz/s3/Energy-panel-customer",
    },
  },
  displayDateFormat: "dddd D MMM",
  apiDateFormat: DATE_FORMAT,
};
export const NPS = Success.nps;

export const Footer = {
  links: [
    {
      title: "Legal",
      href: process.env.NX_EXPRESS_BASE_URL + "/legal",
    },
    {
      title: "Terms and Conditions",
      href: process.env.NX_EXPRESS_BASE_URL + "/legal/terms-and-conditions",
    },
    {
      title: "Privacy",
      href: process.env.NX_EXPRESS_BASE_URL + "/legal/privacy",
    },
  ],
  copyright: `© Contact Energy Ltd, All rights reserved ${new Date().getFullYear()}`,
  contactLogoAlt: "Contact Energy Logo",
};

export const Express = {
  pageTitle: "Contact Energy | Express Move",
  title: "Express move your electricity with the Basic plan",
  detailsTitle: "Basic",
  lowUser: "Low User",
  low: "Low",
  description:
    "Move house faster with this simple, hassle-free plan. Get transparent pricing plus the flexibility of no fixed term and no break fees.",
  includeTitle: "Express move with the Basic plan includes:",
  icons: [
    {
      image: electricityImage,
      text: "Electricity only",
    },
    {
      image: smileImage,
      text: "No fixed term, no break fees, no hassle",
    },
    {
      image: pricintgImage,
      text: "Simple, transparent pricing",
    },
    {
      image: calentarImage,
      text: "Change your plan or add services anytime after your move",
    },
  ],
  planCard: {
    banner: "Change plan anytime",
    tag: "Popular",
    content: {
      plan: "Basic",
      header: "Simple",
      title: " energy rates",
      description: "No fixed term",
    },
  },
  promoCode: {
    title: "Promo code",
    buttonText: "Apply",
    buttonImage: "",
    error: "This promocode is invalid",
  },
  tc: {
    title: "Important things to know",
    bulletPoints: [
      "The plan is available with SmoothPay, Control Pay and Contact PrePay.",
      "The plan is not available to PrePower customers and is not available for any LPG supply.",
      "Our standard billing and payment fees are charged if you receive a paper bill or pay by credit card. You can avoid these fees by choosing to get your correspondence by email or choosing a different payment method.",
      "Overdue payments may incur our standard $25 debt management fee.",
      "Subject to a satisfactory credit check.",
    ],
    file: planTermsAndConditionsURL,
    termsButtonLabel: "Terms & Conditions",
    moduleButtonLabel: "View full terms and conditions",
  },
  property: {
    title: "About your new property",
  },
  moveOutAddress: {
    addressInputLabel: "Address you're moving from",
    addressRequiredMsg: "Address is required",
    dateInputLabel: "Move out date",
    datePlaceholder: "DD / MM / YYYY",
    dateRequiredMsg: "Move out date is required",
    automationIds: {
      autocomplete: "moveOutAddressAutocomplete",
      datepicker: "moveOutAddressDatepicker",
    },
  },
  moveInAddress: {
    addressInputLabel: "Where are you moving to?",
    addressRequiredMsg: "Address is required",
    addressInvalidMsg:
      "Moving to address can not be same as moving from address",
    dateInputLabel: "Move in date",
    datePlaceholder: "DD / MM / YYYY",
    dateMaxMonths: 2,
    dateRequiredMsg: "Move in date is required",
    dateMaxMonthsErrorMsg: "Please check the date entered",
  },
  accountNumber: "Account number #",
  hazards: {
    label: "Potential hazards or access issues at my property",
    optionsOther: "Other",
    helpText:
      "We need this information just in case a meter reader needs to visit.",
    optionsNoHazards: "No hazards",
    options: ["No hazards", "Dog", "Other"],
    otherHazards: "Other hazards not mentioned above",
    otherHazardsPlaceholder: "",
    otherHazardsRequiredMsg: "Hazard details are required.",
  },
  tcCheckbox: {
    label: `I agree to the <a href="${planTermsAndConditionsURL}" download rel="noopener noreferrer">terms and conditions</a> of the Basic Plan`,
    subLabel:
      "We will keep your account preferences the same, for example, contact details and billing and payment methods. Please note, if " +
      "you’re on weekly / fortnightly billing or Contact PrePay we may not be able to transfer these services over to your new property due to " +
      "eligibility criteria, though we will be in touch if there are any issues. If you would like to update your account preferences or sign " +
      "up for other services at your new property, such as gas, please log-in to My Account or the Contact App to do this after your new connection " +
      "has been set up. As you’re an existing customer, any applicable terms and conditions already accepted by you will continue to apply.",
  },
  submitButtonText: "Express move",
  submitButtonSecondaryText: "with the Basic plan",
  expressJourneyType: "EXPRESS-MOVE",
  planInfo: {
    planName: "Basic plan",
    planId: "BASIC",
    campaignId: "R9BASO00",
  },
  dateFormat: DATE_FORMAT,
  redirectOnErrorUrl: process.env.NX_EXPRESS_BASE_URL,
  errorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
  dualCustomerMessage:
    "Please note - Express Move is for customers that only require electricity at their new address. Existing services (e.g. Gas or Broadband) will not carry over to the new property.",
};

export const AppError = {
  message:
    "<p>Apologies, we’re currently experiencing technical issues with your request. To complete your order, " +
    'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
    '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  buttonLabel: "Close",
};

export const ExpressRates = {
  id: "expressRates",
  rateLabels: {
    [SERVICE_ID_ELECTRICITY]: ["Your electricity rates", "Electricity rates"],
    [SERVICE_ID_PIPED_GAS]: ["Your piped gas rates", "Gas rates"],
    [SERVICE_ID_BROADBAND]: ["Your broadband rates", "Broadband rates"],
  },
  messages: {
    noAddress:
      "Your street address and services are required to calculate your rates",
    error:
      "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
      "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
    multipleRates: "There are multiple rates for your Property",
    noElectricityRates:
      "Unfortunately we’re unable to provide you with Electricity Rates for this address. You can still complete your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
    noGasRates:
      "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
      "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
    expand: "View rates",
    collapse: "Hide rates",
    electricityLabel: "Electricity",
    gasLabel: "Piped gas",
    standardUserRates: "standard user rates",
    lowUserRates: "low user rates",
    disclaimer:
      "Energy rates are before GST and any applicable discounts and are based on the plan you selected (or " +
      "our Basic plan if you haven’t chosen one yet). Electricity rates are based on the information available " +
      "for your property, and natural gas rates are the most common in your region. If your property has unmetered load, rates will be confirmed in your welcome letter. We do our best to provide accurate rates, but there are rare " +
      "occasions where your actual prices may differ and we will confirm these once we have worked through all " +
      "the details.",
    usageTypeSwitch: "Switch to %usageType% user rates",
  },
};

export const Gtm = {
  reactAppId: process.env.NX_GTM_ID,
};

export const ApiError = {
  errorTitle: "Something went wrong.",
  errorMessage: "Please try again.",
  serverErrorMessage: "Please try again or proceed with journey.",
  ccServerErrorMessage:
    "Credit check operation failed, please try again or proceed with journey.",
};

export const Tracking = {
  ELEC: "Electricity",
  GAS: "Piped Gas",
  LPG: "Bottled Gas",
  BB: "Broadband",
  LPG_Id: "LPG-ONLY",
  LPG_Name: "LPG Only",
  valid: "valid",
  invalid: "invalid",
  fixedTerm: "Fixed term",
  noFixedTerm: "No fixed term",
  joinJourney: {
    item_category: "New",
    item_category2: "Join",
    name: "New/Join",
  },
  fail: "failed",
  pass: "passed",
  noUse: "not used",
  moveJourney: {
    item_category: "Existing",
    item_category2: "MoveHouse",
  },
  addJourney: {
    item_category: "Existing",
    item_category2: "AddProperty",
  },
};

export const Titles = [
  "Mr",
  "Mrs",
  "Miss",
  "Ms",
  "Mx",
  "Doctor",
  "Reverend",
  "Professor",
  "Lady",
  "Sir",
  "Captain",
  "Senior",
  "Sister",
  "Dame",
  "Judge",
  "MP",
  "Pastor",
  "Commander",
  "Junior",
  "MDM",
  "Master",
  "Bishop",
  "Estate of",
];

export const AuthorizedPersonRelationshipTypeOptions = [
  "ALT",
  "AUT",
  "POW",
  "COE",
];

export const AuthorizedPersonRelationshipTypeMappings = {
  ALT: "Alternative Contact",
  AUT: "Authorised Person",
  POW: "Power of Attorney",
  COE: "Copy of Invoice to Email",
};

export const BottleGasZones = [SELECT_ZONE, BRANCH, FRANCHISE];

export const servicesFullName = {
  ELEC: "Electricity",
  GAS: "Piped Gas",
  LPG: "Bottled Gas",
  BB: "Broadband",
};

export const refreshToken = {
  maxIterations: 1,
  timeInterval: 28 * 60 * 1000,
};

export const contactCookieDomain = "contact.co.nz";

export const sitecoreVariables = {
  promoCodeCookieName: "sharedPromocode",
  servicesCookieName: "selectedService",
  sharedPlanIdCookieName: "sharedPlanId",
  saveForLaterCookieName: "saveForLaterParameters",
};

export const emailDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "xtra.co.nz",
  "live.com",
  "mail.com",
  "msn.com",
  "aol.com",
];
