import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { PaymentMethods } from './models';
import { PaymentMethodsPath, PaymentMethodsVersion } from './apiPaths';

export const getPaymentMethods = (
  version: PaymentMethodsVersion,
  token: string,
  bpId: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<PaymentMethods.PaymentMethodsApiResponse>(
    PaymentMethodsPath[version],
    {
      headers: {
        session: token,
      },
      params: {
        businessPartnerId: bpId,
      },
    }
  );
};
