import * as actionTypes from "../actionTypes";
import { getApiState } from "../ApiCalls";
import { MyDetailsFixture } from "../../Fixtures/CustomerJoin/MyDetailsResponse";
import {
  updateAuthorizedPerson,
  updateUnusedAuthorizedPerson,
} from "../CustomerJoin/AuthorizedPersonAction";
import { updateCustomer } from "./CustomerActions";
import { updateProperty } from "./PropertyActions";
import { updateBillCompareOutput } from "./BillCompareActions";
import { updatePipedGas } from "./GasActions";
import { updatePayment } from "./PaymentActions";
import { updateBroadband } from "./BroadbandActions";
import { updateServices } from "./ServicesActions";
import { updateConfirmation } from "./ConfirmationActions";
import { updateReadonly } from "./ReadonlyActions";
import { updatePlan } from "./PlanActions";
import { updateJourneyType } from "../CustomerJoin/JourneyTypeAction";
import { updateJourneyChannel } from "../CustomerJoin/JourneyChannelAction";
import { updateSaveForLaterId } from "../CustomerJoin/SaveForLaterIdAction";
import { saveCreditCheckResult } from "../CustomerJoin/CreditCheckAction";
import {
  updateJointAccountHolder,
  updateUnusedJointAccountHolder,
} from "../CustomerJoin/JointAccountAction";
import { updateSnapshot } from "../../Reducers/SnapshotRedux";
import { getBillingEligibilityFetched } from "./BillingEligibilityActions";

export const updateAuth = (sessionID, deleteAfter) => {
  return {
    type: actionTypes.UPDATE_AUTH,
    auth: { sessionID: sessionID, deleteAfter: deleteAfter },
  };
};

export const updateProp = (propName, value) => {
  return {
    type: actionTypes.UPDATE_PROP,
    propName,
    value,
  };
};

function buildReadOnly({
  ApiPlan,
  Eligibilities,
  SitecoreContactId,
  PriceChecker,
  Discounts,
  BroadbandId,
  BroadbandFullName,
}) {
  return {
    ApiPlan,
    Eligibilities,
    SitecoreContactId,
    PriceChecker,
    Discounts,
    BroadbandId,
    BroadbandFullName,
  };
}

export const updateMyDetails = (apiData) => {
  const {
    sessionID,
    deleteAfter,
    Auth,
    Customer,
    LpgInfo,
    PipedGasInfo,
    Payment,
    BroadbandInfo,
    Services,
    Property,
    PropertyInfo,
    PlanInfo,
    MedicalInfo,
    CreditCheckInfo,
    CreditCheck,
    MoveInfo,
    BillCompare,
    JointAccountHolderInfo,
    UnusedJointAccountHolderInfo,
    AuthorizedPersonInfo,
    UnusedAuthorizedPersonInfo,
    Confirmation,
    JourneyChannel,
    JourneyType,
    SaveForLaterId,
    Snapshot,
    BillingEligibility,
    ...rest
  } = apiData;

  return [
    sessionID && updateAuth(sessionID, deleteAfter),
    !sessionID && Auth && updateAuth(Auth.sessionID, Auth.deleteAfter),
    updateReadonly(buildReadOnly(rest)),
    JourneyChannel && updateJourneyChannel(JourneyChannel),
    JourneyType && updateJourneyType(JourneyType),
    SaveForLaterId && updateSaveForLaterId(SaveForLaterId),
    PlanInfo && updatePlan(PlanInfo),
    BillCompare && updateBillCompareOutput(BillCompare),
    PipedGasInfo && updatePipedGas(PipedGasInfo),
    Payment && updatePayment(Payment),
    BroadbandInfo && updateBroadband(BroadbandInfo),
    (CreditCheckInfo && saveCreditCheckResult(CreditCheckInfo)) ||
      (CreditCheck?.data && saveCreditCheckResult(CreditCheck.data)),
    Services && updateServices(Services),
    Property
      ? updateProperty(Property)
      : updateProperty({
          PropertyInfo: PropertyInfo,
          MedicalInfo: MedicalInfo,
          MoveInfo: MoveInfo,
        }),
    JointAccountHolderInfo && updateJointAccountHolder(JointAccountHolderInfo),
    UnusedJointAccountHolderInfo &&
      updateUnusedJointAccountHolder(UnusedJointAccountHolderInfo),
    Confirmation && updateConfirmation(Confirmation),
    AuthorizedPersonInfo && updateAuthorizedPerson(AuthorizedPersonInfo),
    UnusedAuthorizedPersonInfo &&
      updateUnusedAuthorizedPerson(UnusedAuthorizedPersonInfo),
    Customer && updateCustomer(Customer),
    // do this last so that data are populated in the store
    Snapshot && updateSnapshot(Snapshot),
    BillingEligibility &&
      BillingEligibility.data &&
      getBillingEligibilityFetched(BillingEligibility.data),
  ];
};

export const fetchMyDetailsData = () => {
  return process.env.NX_USE_FIXTURES
    ? updateMyDetails(MyDetailsFixture)
    : getApiState();
};
