import React, { useState, useEffect, useRef, useCallback } from "react";
import axios, { AxiosRequestConfig } from "axios";
import Autosuggest from "react-autosuggest";
import Paper from "@material-ui/core/Paper";
import debounce from "lodash/debounce";
import classNames from "classnames";

import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Input from "../Input";

import "./BroadbandAddressSearch.scss"; // Created separate styles though all the styles are the same as Address component in order to decouple
import customInput from "../Address/CustomInput";
import renderSuggestion from "../Address/Suggestion";
import noop from "nop";
import AddressForm from "../Address/AddressForm";
import { BroadbandLookupPath } from "@contact/data-access";

const noAddress = "Can’t find your address?";

interface SuggestionValue {
  full_address: string;
}

interface Suggestion {
  id: number;
  name: string;
  value: SuggestionValue;
}
// Material UI styles
const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      height: 60,
      marginBottom: "2rem",
      flexGrow: 1,
    },
    container: {
      position: "relative",
    },
    suggestionsContainerOpen: {
      position: "relative", // Ensures the the suggestion container renders without the need of scrolling in service modal and service cards
      zIndex: 1,
      marginTop: spacing.unit,
      left: 0,
      right: 0,
    },
    suggestion: {
      display: "block",
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    divider: {
      height: spacing.unit * 2,
    },
  });

const noAddressSuggestion = {
  id: "no-address",
  name: noAddress,
  value: null,
};

async function fetchAddressSuggestions(address, api) {
  const url = api.baseUrl + BroadbandLookupPath.v2 + "?address=" + address;
  const config: AxiosRequestConfig = {
    method: "GET", // The method is required. It will default to "GET" in dev, but it will break in *production*!
    headers: {
      "Content-Type": "application/json",
      "x-api-key": api.key,
    },
  };
  const result = await axios(url, config);

  if (result.data && result.data.data) {
    const size = 5;

    const { data } = result.data;

    const addresses = data
      .slice(0, size)
      .map((ad, index) => ({ id: index, name: ad.full_address, value: ad }));
    addresses.push(noAddressSuggestion);
    return addresses;
  }
}

const debouncedFetchSuggestions = debounce((api, onSuccess, onError, value) => {
  if (value) {
    fetchAddressSuggestions(value, api).then(onSuccess).catch(onError);
  }
}, 250);

function composeAddressValue(address) {
  if (address) {
    return {
      full_address: address,
    };
  }
  return null;
}

function IntegrationAutosuggest({
  api,
  classes,
  value: valueProp,
  handleChange,
  handleMonikerChange,
  handleError,
  disabled,
  loaderCustomStyle,
  rightContent,
  labelText,
  handleSetManualAddress,
  validateItself,
  suburbRequired,
  manualAddress,
}) {
  const [value, setValue] = useState(composeAddressValue(valueProp));
  const [inputValue, setInputValue] = useState(valueProp || "");

  // update input value state from props
  const currentInputValue = useRef(inputValue);
  currentInputValue.current = inputValue;
  const currentValue = useRef(value);
  currentValue.current = value;
  useEffect(() => {
    setInputValue(valueProp || "");
  }, [valueProp]);

  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);

  // Fire change event handler anytime the value changes
  const handleChangeRef = useRef(handleChange);
  handleChangeRef.current = handleChange;
  const handleMonikerChangeRef = useRef(handleMonikerChange);
  handleMonikerChangeRef.current = handleMonikerChange;
  useEffect(() => {
    handleChangeRef.current((value && value.full_address) || "");
    if (handleMonikerChangeRef.current) {
      handleMonikerChangeRef.current(value && value.full_address);
    }
  }, [value]);

  // Will be called every time you need to recalculate suggestions.
  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedFetchSuggestions(
      api,
      (suggestions) => {
        setSuggestions(suggestions);
      },
      handleError,
      value
    );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.name;

  // Will be called every time suggestion is selected via mouse or keyboard.
  const onSuggestionSelected = (e, { suggestion }) => {
    setValue(suggestion.value);
  };

  const handleAddress = useCallback(
    (address, details) => {
      handleSetManualAddress(address, details);
    },
    [handleSetManualAddress]
  );

  const autosuggestProps = {
    suggestions: suggestions,
    onSuggestionsFetchRequested,
    //the suggestions must be kept because the user can click again in the field
    onSuggestionsClearRequested: noop,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
  };

  const getAddressFromSuggestion = (address) => {
    const addressList = suggestions.filter(
      (sug: Suggestion) => sug.name === address
    );
    return addressList.length > 0 ? addressList[0] : null;
  };

  const onInputChange = (e, { newValue }) => {
    setInputValue(newValue);
    const address: Suggestion | null = getAddressFromSuggestion(newValue);
    address ? setValue(address.value) : setValue(null);
  };

  const onInputBlur = () => {
    const address: Suggestion | null = getAddressFromSuggestion(inputValue);
    address ? setValue(address.value) : setValue(null);
  };

  if (disabled) {
    return (
      <Input
        className={classes}
        labelText={labelText}
        value={inputValue}
        disabled
      />
    );
  }

  const addressClassName = classNames(classes.root, {
    "address--autosuggest-container": true,
    "address--with-right-content": rightContent,
  });

  return (
    <div className={addressClassName}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          value: inputValue,
          onChange: onInputChange,
          onBlur: onInputBlur,
          customstyle: loaderCustomStyle,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={(inputProps) =>
          customInput(inputProps, { labelText, handleError }, inputValue)
        }
        renderSuggestionsContainer={({ containerProps, children }) => {
          return (
            <Paper {...containerProps} square>
              {children}
            </Paper>
          );
        }}
        shouldRenderSuggestions={(inputVal) => inputVal.length >= 2}
      />
      {inputValue === noAddress && (
        <AddressForm
          handleChange={handleAddress}
          value={manualAddress}
          handleError={handleError}
          validateItself={validateItself}
          suburbRequired={suburbRequired}
          hasEnterButton={false}
          showButton={false}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(IntegrationAutosuggest);
